//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-260:_6827,_2184,_2608,_9056,_9992,_1848,_5700,_9552;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-260')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-260', "_6827,_2184,_2608,_9056,_9992,_1848,_5700,_9552");
        }
      }catch (ex) {
        console.error(ex);
      }